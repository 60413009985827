import API from "../../api/api";


const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const ShoppingService = {
    callRepurchaseItems: async () => {
        const response = await callApi('repurchase-products');
        return response?.data;
    },
    callGetInventory: async (orderType) => {
        const response = await callApi(`get-inventory?type=${orderType}`);
        return response?.data;
    },
    callCartItems: async () => {
        const response = await callApi('get-cart');
        return response?.data;
    },
    callAddToCart: async (data) => {
        return API.post("add-to-cart", JSON.stringify(data))
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    callDecrementCartItem: async (data) => {
        return API.patch("decrement-cart-item", JSON.stringify(data))
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    callRemoveCartItem: async (data) => {
        return API.delete("remove-cart-item", { data: JSON.stringify(data) })
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    callAddAddress: async (data) => {
        return API.post("add-address", JSON.stringify(data))
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    callAddress: async () => {
        const response = await callApi('get-address');
        return response?.data;
    },
    callPaymentMethods: async (action) => {
        const response = await callApi(`payment-methods?action=${action}`);
        return response?.data;
    },
    callRemoveAddress: async (data) => {
        return API.delete("delete-address", { data: JSON.stringify(data) })
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    callProductDetails: async (id) => {
        const response = await callApi(`product-details?id=${id}`);
        return response?.data;
    },
    callDefaultAddressChange: async (id) => {
        return API.patch(`change-default-address?newDefaultId=${id}`)
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    callPlaceOrder: async (data) => {
        return API.post("place-order", JSON.stringify(data))
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    callOrderReport: async (page, limit, sortBy, direction) => {
        const response = await callApi(`order-report?page=${page}&perPage=${limit}&sortBy=${sortBy}&direction=${direction}`);
        return response?.data;
    },
    callOrderInvoice: async (invoiceNo) => {
        return await callApi(`order-invoice?invoiceNo=${invoiceNo}`);
    },
    callCreateReport: async (reportType, fileType) => {
        const response = await API.post(`create-report?reportType=${reportType}&fileType=${fileType}`);
        return response?.data;
    }
}