import React, {useEffect, useState} from "react";
import TicketHeader from "./TicketHeader";
import TicketForm from "./TicketForm";
import {ApiHook} from "../../hooks/apiHook";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setHeader} from "../../store/reducers/pageReducer";

const CreateTicket = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("create_ticket")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

    const [selectedMenu, setSelectedMenu] = useState(false);

    const partials = ApiHook.CallTicketPartials()
    const trackId = ApiHook.CallTrackId();

    const handleSideMenuToggle = () => {
        setSelectedMenu(!selectedMenu);
    };

    return (
        <>
            <div className={`page_head_top ${selectedMenu ? 'show_mailbox_sidebar_ico' : ''}`}>
                {pageHeader}
                <div className="right_btn_mob_toggle" onClick={handleSideMenuToggle}>
                    <i className="fa fa-bars"></i>
                </div>
            </div>
            <TicketHeader selectedMenu={selectedMenu} />
            <TicketForm partials={partials.data} trackId={trackId.data}/>
        </>
    );
};

export default CreateTicket
