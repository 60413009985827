import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import MailboxComponent from "../../components/mailbox/MailboxComponent";
import {useDispatch, useSelector} from "react-redux";
import {setHeader} from "../../store/reducers/pageReducer";

const MailBox = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("mailBox")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

    const [showMobileNav, setShowMobileNav] = useState(false);
    return (
        <>
            <div className={`page_head_top mb-3 ${showMobileNav ? "show_mailbox_sidebar_ico" : ""}`}>
                {pageHeader}
                <div className="right_btn_mob_toggle" onClick={() => setShowMobileNav(!showMobileNav)}>
                    <i className="fa fa-bars"></i>
                </div>
            </div>
            <MailboxComponent showMobileNav={showMobileNav}/>
        </>
    );
};

export default MailBox;
