import React, {useEffect, useRef, useState} from "react";
import SubmitButton from "../Common/buttons/SubmitButton";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import {useTranslation} from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {formatDateWithoutTime} from "../../utils/formateDate";

const RenewComponents = ({
                             products,
                             subscriptionDetails,
                             handleRenewClick,
                             currency,
                             conversionFactor,
                         }) => {
    const {t} = useTranslation();
    const userData = useSelector((state)=> state.user?.loginResponse?.user);
    const user = JSON.parse(userData || "{}");
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const previousPage = searchParams.get("previousPage") ?? '/dashboard';

    const [progress, setProgress] = useState(100);
    const progressBarRef = useRef(null);
    const daysLeftRef = useRef(null);

    const productValidity = subscriptionDetails?.productValidity;

    useEffect(() => {
        progressBarRef.current.style.strokeDashoffset = progress === 0 ? 0 : 100 - progress;
        progressBarRef.current.style.stroke = productValidity?.color;
        daysLeftRef.current.style.backgroundColor = productValidity?.color;
    }, [productValidity?.color, progress]);

    useEffect(() => {
        setProgress(productValidity?.percentage);
    }, [productValidity?.percentage]);

    return (
        <div className="package_upgrade_cnt_sec">
            <h4>
                <Link to={previousPage} replace={true} className="back_btn">
                    <i className="fa-solid fa-arrow-left"></i>
                </Link>
            </h4>
            <div className="renew-plan">
                <div className="row justify-content-center">
                    <div className="col-lg-12 borderPofileStyle">
                            <div className="text-center">
                                <div className="user_avatar_extra_padding avatarProfileStyle">
                                    <img src={`${subscriptionDetails?.image ?? "/images/profile-image-new.png"}`} alt=""/>
                                    <svg
                                        className="profile_progress avatarProfileProgress"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="-1 -1 34 34"
                                    >
                                        <circle
                                            cx="16"
                                            cy="16"
                                            r="15.9155"
                                            className="progress-bar__background"
                                        />
                                        <circle ref={progressBarRef}
                                                cx="16"
                                                cy="16"
                                                r="15.9155"
                                                className="progress-bar__progress js-progress-bar"
                                        />
                                    </svg>
                                </div>
                                <h4>{user?.fullName}</h4>
                                <span className="renewn_remainingdys mb-10" ref={daysLeftRef}>{subscriptionDetails?.daysLeft} {t("daysLeft")}</span>
                                <div className="kycDetailProfile">
                                    <div className="kycDetailProfile_cnt">
                                        <h6 className="packge_name_bx">
                                            {subscriptionDetails?.packageName}
                                        </h6>
                                    </div>
                                    <div className="kycDetailProfile_cnt">
                                        <h6>{t("purchasedOn")}:</h6>
                                        <h6>{formatDateWithoutTime(subscriptionDetails?.purchaseDate)}</h6>
                                    </div>
                                    <div className="kycDetailProfile_cnt">
                                        <h6>{t("expiration")}:</h6>
                                        <h6>{formatDateWithoutTime(productValidity?.date)}</h6>
                                    </div>

                                    {/* <FormControlLabel
                            control={
                              <Switch
                                defaultValue={true}
                                checked={!!autoRenew}
                                onChange={handleSubscriptionToggleBtn}
                                color="secondary"
                              />
                            }
                            label={t("auto_renewal")}
                            labelPlacement="start"
                          /> */}
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div className="plan-options">
                {!products ? (
                    <>
                        <Skeleton
                            height={550}
                            highlightColor="#d9d1e1"
                            width={350}
                            count={1}
                        />
                        <Skeleton
                            height={550}
                            highlightColor="#d9d1e1"
                            width={350}
                            count={1}
                        />
                        <Skeleton
                            height={550}
                            highlightColor="#d9d1e1"
                            width={350}
                            count={1}
                        />
                    </>
                ) : (
                    products?.map((product, index) => (
                        <div key={index}>
                            <div className="plan" key={index}>
                                <div className="plan-header">
                                    <h3 className="plan-heading">{product?.name}</h3>
                                </div>
                                <img src={product?.image} alt={product?.name}
                                     className="plan-image"/>
                                <div className="upgrade_desc">
                                    <p className="amnt_view_plan">
                                        {currency?.symbolLeft}
                                        <span
                                            className="plan-amount">{CurrencyConverter(product?.price, conversionFactor)}</span>
                                        <span
                                            className="pvValuePlan">{`${t("pv")} : ${product?.pairValue}`}</span>
                                    </p>
                                </div>
                                {/*<ul className="plan-feature">
                                    <li>
                                        {t("upgradeValidity")} : {product?.pack?.validity}
                                    </li>
                                </ul>*/}
                                <p className="plan_btn">
                                    <SubmitButton
                                        className="plan-choose"
                                        text="select"
                                        click={() => handleRenewClick(product)}
                                    />
                                </p>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default RenewComponents;
