import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer.js';
import dashboardReducer from './reducers/dashboardReducer.js';
import treeReducer from './reducers/treeReducer.js';
import replicaReducer from './reducers/replica.js'
import mailBoxReducer from './reducers/mailBoxReducer.js';
import pageReducer from "./reducers/pageReducer";

const rootReducer = combineReducers({
  user: userReducer,
  tree: treeReducer,
  dashboard: dashboardReducer,
  replica: replicaReducer,
  mail: mailBoxReducer,
  page: pageReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
