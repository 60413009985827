import React from 'react';
import { ApiHook } from '../../../hooks/apiHook';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import CurrencyConverter from '../../../Currency/CurrencyConverter';
import { useLocation } from 'react-router';

const PartyProducts = (props) => {

  return (

    <div className="table-responsive min-height-table-2 mb-4">
      <div className="p-3">
        <div className="py-0">
          <h5 className="m-0">Add to cart</h5>
        </div>

        <table className="table table-manage-invite-party-portal mb-0 border-top1">
          <thead>
            <tr>
              <th>No</th>
              <th width="40%">Product Name</th>
              <th>Price</th>
              <th>Stock</th>
              {/* <th>Quantity</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(props?.products) ? (
              props?.products?.map((product, index) => (
                <ProductRow
                  key={`${product.id}-${index}`}
                  product={product}
                  props={props}
                  index={index}
                />
              ))
            ) : (
              <tr>
                <td colSpan="6">No products found...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>

  );
};

const ProductRow = ({ product, index, props }) => {
  // const [quantity, setQuantity] = useState(1);
  const queryClient = useQueryClient();
  const location = useLocation()
  const partyId = location.state?.partyId ?? JSON.parse(localStorage.getItem('partyId'));
  const guestId = location.state?.guestId ?? JSON.parse(localStorage.getItem('guestId'));
  //------------------------------- API ------------------------------
  const addToCartMutation = ApiHook.CallAddToPartyCart()
  // const createOrder = ApiHook.CallCreateGuestOrder();

  const addToCart = (e, productId) => {
    e.preventDefault();
    const body = {
      partyId: partyId,
      guestId: guestId,
      productId: productId,
      quantity:1
    }
    addToCartMutation.mutate(body, {
      onSuccess: (res) => {
        if (res.status) {
          queryClient.invalidateQueries({ queryKey: ["party-products"] });
        } else {
          toast.error(res.description);
        }
      }
    })
  }

  // const handleIncrement = () => {
  //   setQuantity(prevQuantity => Math.min(prevQuantity + 1, 100));
  // };

  // const handleDecrement = () => {
  //   setQuantity(prevQuantity => Math.max(prevQuantity - 1, 1));
  // };

  // const handleQuantityChange = (e) => {
  //   const parsedValue = parseInt(e.target.value, 10);
  //   if (!isNaN(parsedValue) && parsedValue > 0 && parsedValue <= 100) {
  //     setQuantity(parsedValue);
  //   }
  // };

  // const handleAddToCart = (e) => {
  //   e.preventDefault();
  //   const data = {
  //     partyId: props.partyId,
  //     guestId: props.guestId,
  //     productId: product.id,
  //     quantity
  //   };
  //   createOrder.mutate(data, {
  //     onSuccess: (res) => {
  //       if (res.status) {
  //         toast.success(res.data)
  //         queryClient.invalidateQueries({ queryKey: ["party-products"] })
  //       } else {
  //         toast.error(res.data.description)
  //       }
  //     }
  //   })
  // };

  return (
    <tr key={product.id}>
      <td>{index + 1}</td>
      <td>{product.name}</td>
      <td>{props.currency?.symbolLeft}{" "}{CurrencyConverter(product?.price, props.conversionFactor)}</td>
      <td>{product.stock}</td>
      {/* <td>
        <div className="quantity">
          <button className="minus" aria-label="Decrease" onClick={handleDecrement}>&minus;</button>
          <input
            type="text"
            className="input-box"
            value={quantity}
            onChange={handleQuantityChange}
            min="1"
            max="10"
          />
          <button className="plus" aria-label="Increase" onClick={handleIncrement}>+</button>
        </div>
      </td> */}
      <td>
        <form onSubmit={(e) => addToCart(e, product.id)}>
          <div className="d-flex">
            <button type="submit" className="btn btn-primary ms-2">Add to cart</button>
          </div>
        </form>
      </td>
    </tr>
  );
};

export default PartyProducts;
