import { format } from "date-fns";
import { enUS, es, pt } from "date-fns/locale";
import i18n from "i18next";

const locales = { en: enUS, es, pt };

export const formatDate = (dateString) => {
  if (!dateString) {
    return ""; // Handle empty or null values gracefully.
  }
  return format(new Date(dateString), "PPpp", {
    locale: locales[i18n.language],
  });
};

export const formatDateWithoutTime = (dateString) => {
  if (!dateString) {
    return ""; // Handle empty or null values gracefully.
  }
  return format(new Date(dateString), "PP", {
    locale: locales[i18n.language],
  });
}

export const ticketFormatDate = (dateString) => {
  if (!dateString) {
    return ""; // Handle empty or null values gracefully.
  }
  return format(new Date(dateString), 'dd/MMM/yyyy  HH:mm');
};

export const crmFormateDate = (dateString) => {
  if (!dateString) {
    return ""; // Handle empty or null values gracefully.
  }
  return format(new Date(dateString), 'MM-dd-yyyy');
}