import React from "react";
import {useSelector} from "react-redux";
import TableContent from "./table/TableContent";

export const PrintReport = React.forwardRef((props, ref) => {
    const companyProfile = useSelector(
        (state) => state?.dashboard?.appLayout?.companyProfile
    );

    return (
        <div className="printReport" ref={ref}>
            <div className="table-responsive min-height-table rounded">
                <div className="cmpnyAddressname">
                    <div>
                        <img src={companyProfile?.logo ?? null} alt=""/>
                    </div>
                    <div>
                        <p className="mb-1">{companyProfile?.name}</p>
                        <p>{companyProfile?.address}</p>
                    </div>
                </div>
                <TableContent
                    print={true}
                    data={props.data}
                    headers={props.headers}
                    type={props.type}
                />
            </div>
        </div>
    );
});
