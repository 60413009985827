import React from "react";
import { useTranslation } from "react-i18next";
import PartyTableContent from "./PartyTableContent";
import TablePagination from "../Common/table/TablePagination";
import { NavLink, useLocation } from "react-router-dom";
const PartyTableMain = (props) => {
    const { t } = useTranslation();
    const location = useLocation();

    const startPage = 1;
    const handleItemsPerPageChange = (event) => {
        const selectedValue = parseInt(event.target.value);
        props.setItemsPerPage(selectedValue);
        props.setCurrentPage(1);
    }
    const toNextPage = () => {
        if (props.currentPage < props?.tableData?.totalPages) {
            props.setCurrentPage(props.currentPage + 1);
        }
    }
    const toLastPage = () => {
        props.setCurrentPage(props?.tableData?.totalPages);
    };
    const toPreviousPage = () => {
        if (props.currentPage > props.startPage) {
            props.setCurrentPage(props.currentPage - 1);
        }
    };
    const toFirstPage = () => {
        props.setCurrentPage(startPage);
    };
    return (
        <>
            <div className="ewallet_table_section">
                <div className="ewallet_table_section_cnt">
                    {/* filter-section */}
                    <div className="filter_Section">
                        <div className="row justify-content-between align-items-center">
                            <div className="right_search_div d-flex gap-1 justify-content-end">
                                {location.state?.selectedParty &&
                                   
                                    <NavLink to="/my-party-portal" state={{ selectedParty: location.state?.selectedParty }}>
                                        <button className="btn btn-primary"><i className="fa-solid fa-arrow-left"></i> Back to Party Portal</button>
                                    </NavLink>

                                }
                                <NavLink to={props?.filterButtonPath} state={{ selectedParty: location.state?.selectedParty }}>
                                    <button className="btn btn-primary"><i className="fa-solid fa-user-plus"></i>  {t(props?.filterButtonText)}</button>
                                </NavLink>

                            </div>
                        </div>
                    </div>
                    <div className="table-responsive min-height-table">
                        {/* table-content */}
                        <PartyTableContent
                            tableContent={props?.tableData?.[props?.type]}
                            currentPage={props.currentPage}
                            path={props.filterButtonPath} />
                        {/* paginatiopn */}
                        <TablePagination
                            startPage={1}
                            currentPage={props?.tableData?.currentPage}
                            totalPages={props?.tableData?.totalPages}
                            itemsPerPage={props?.itemsPerPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                            toNextPage={toNextPage}
                            toLastPage={toLastPage}
                            toPreviousPage={toPreviousPage}
                            toFirstPage={toFirstPage}
                        />
                    </div>

                </div>
            </div >
        </>
    )
};

export default PartyTableMain;
