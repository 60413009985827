import {ShoppingService} from "../../services/shopping/shopping"

export const ShoppingActions = {
    getRepurchaseItems: async () => {
        try {
            return await ShoppingService.callRepurchaseItems()
        } catch (error) {
            return error.message
        }
    },
    getInventory: async (orderType) => {
        try {
            return await ShoppingService.callGetInventory(orderType)
        } catch (error) {
            return error.message
        }
    },
    addToCart: async (data) => {
        try {
            return await ShoppingService.callAddToCart(data)
        } catch (error) {
            return error.message
        }
    },
    getCartItems: async () => {
        try {
            return await ShoppingService.callCartItems()
        } catch (error) {
            return error.message
        }
    },
    decrementCartItem: async (data) => {
        try {
            return await ShoppingService.callDecrementCartItem(data)
        } catch (error) {
            return error.message
        }
    },
    removeCartItem: async (data) => {
        try {
            return await ShoppingService.callRemoveCartItem(data)
        } catch (error) {
            return error.message
        }
    },
    addAddress: async (data) => {
        try {
            return await ShoppingService.callAddAddress(data)
        } catch (error) {
            return error.message
        }
    },
    getAddress: async () => {
        try {
            return await ShoppingService.callAddress()
        } catch (error) {
            return error.message
        }
    },
    callPaymentMethods: async (action) => {
        try {
            return await ShoppingService.callPaymentMethods(action)
        } catch (error) {
            return error.message
        }
    },
    removeAddress: async (data) => {
        try {
            return await ShoppingService.callRemoveAddress(data)
        } catch (error) {
            return error.message
        }
    },
    ProductDetails: async (id) => {
        try {
            return await ShoppingService.callProductDetails(id)
        } catch (error) {
            console.log(error.message);
        }
    },
    DefaultAddressChange: async (id) => {
        try {
            return await ShoppingService.callDefaultAddressChange(id)
        } catch (error) {
            console.log(error.message);
        }
    },
    PlaceRepurchaseOrder: async (data) => {
        try {
            return await ShoppingService.callPlaceOrder(data)
        } catch (error) {
            console.log(error.message);
        }
    },
    OrderReport: async (page, limit, sortBy, direction) => {
        try {
            return await ShoppingService.callOrderReport(page, limit, sortBy, direction)
        } catch (error) {
            console.log(error.message);
        }
    },
    OrderInvoice: async (invoiceNo) => {
        try {
            return await ShoppingService.callOrderInvoice(invoiceNo);
        } catch (error) {
            console.log(error.message);
        }
    },
    CreateReport: async (data) => {
        try {
            return await ShoppingService.callCreateReport(data.reportType, data.fileType)
        } catch (error) {
            console.log(error.message);
        }
    }
}