import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const PartyDetails = () => {
    const { t } = useTranslation();
    return (
        <div className="table-responsive min-height-table-2">

            <div className="p-3">
                <div className="regsiter_step_1_view_left_sec_head mb-0">{t('partyPortal')}</div>
                <span className="inter-line-hori"></span>
                <p className="mt-2">{t('partyIntroGuide')}</p>

                <Link to="/setup-party" className="btn mt-2 rounded-3 setup-Party text-white">
                    <span className="me-2"><i className="fa-solid fa-plus"></i></span>{t('setupParty')}
                </Link>
            </div>
        </div>
    )
}

export default PartyDetails