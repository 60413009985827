import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {ApiHook} from "../../hooks/apiHook";
import Skeleton from "react-loading-skeleton";
import {Tooltip} from "@mui/material";

const UserProfileAvatar = ({ profile, moduleStatus, userKyc }) => {
  const { t } = useTranslation();
  const progressBarRef = useRef(null);
  const [progress, setProgress] = useState(100);
  const daysLeftRef = useRef(null);
  // const [selectedFile, setSelectedFile] = useState(null);
  //------------------------------------ API ---------------------------------
  const updateAvatarMutation = ApiHook.CallUpdateProfilePicture();
  const deleteProfileMutation = ApiHook.CallDeleteProfileAvatar();

  const productValidity = profile?.subscriptionDetails?.productValidity;

  useEffect(() => {
    progressBarRef.current.style.strokeDashoffset = progress === 0 ? 0 : 100 - progress;
    progressBarRef.current.style.stroke = productValidity?.color;
    daysLeftRef.current.style.backgroundColor = productValidity?.color;
  }, [productValidity?.color, progress]);

  useEffect(() => {
    setProgress(productValidity?.percentage);
  }, [productValidity?.percentage]);

  const handleImageChange = (event) => {
    event.preventDefault(); // Prevent default behavior
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      updateAvatarMutation.mutate(selectedFile, {
        onSuccess: (res) => {
          if (res.status) {
            // Clear the value of the file input
            const fileInput = document.getElementById("fileInput");
            if (fileInput) {
              fileInput.value = null;
            }
          }
        }
      });
    }
  };

  const deleteProfilePicture = () => {
    deleteProfileMutation.mutate();
  };

  return (
    <div className="col-lg-3 col-md-12 borderPofileStyle">
      <div className="rightSide_top_user_dropdown">
      <Tooltip
          title={productValidity?.date === null ? t("inactiveUpgrade") : profile?.subscriptionDetails?.daysLeft === 0 ? t("inactiveRenew") : (profile?.subscriptionDetails?.daysLeft <= 21 && t("expiresSoon"))}
          className="rightSide_top_user_dropdown_avatar_container"
          disableFocusListener={!!productValidity?.percentage}
          disableHoverListener={!!productValidity?.percentage}
          disableTouchListener={!!productValidity?.percentage}
          children={
            <div>
              <div className="rightSide_top_user_dropdown_avatar_sec">
                <div className={"avatarButtons"}>
                  <button onClick={() => document.getElementById("fileInput").click()}>
                    <i className="fa-solid fa-edit"></i>
                    <input
                        type="file"
                        id="fileInput"
                        style={{display: "none"}}
                        onChange={handleImageChange}
                    />
                  </button>
                  <button onClick={deleteProfilePicture}>
                    <i className="fa-solid fa-trash"></i>
                    <input type="file" id="fileInput" style={{display: "none"}}/>
                  </button>
                </div>
                {moduleStatus?.kyc_status === 0 && (
                    <div className="kyc_vrfd profileKycVerified">
                      <img src="/images/kyc_vrfd.svg" alt=""/>
                    </div>
                )}
                {moduleStatus?.kyc_status === 1 && (
                    userKyc ? (
                        <div className="kyc_vrfd profileKycVerified">
                          <img src="/images/kyc_vrfd.svg" alt=""/>
                        </div>
                    ) : (
                        <NavLink to={"/kyc-details"} className="kyc_vrfd profileKycVerified">
                          <img src="/images/kyc_not_vrfd.png" alt=""/>
                        </NavLink>
                    )
                )}
                <div className="user_avatar_extra_padding avatarProfileStyle">
                  <img
                      src={
                        profile?.avatar ? profile?.avatar : "/images/user-profile.png"
                      }
                      alt=""
                  />
                  <svg
                      className="profile_progress avatarProfileProgress"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-1 -1 34 34"
                  >
                    <circle
                        cx="16"
                        cy="16"
                        r="15.9155"
                        className="progress-bar__background"
                    />
                    <circle
                        cx="16"
                        cy="16"
                        r="15.9155"
                        className="progress-bar__progress js-progress-bar"
                        ref={progressBarRef}
                    />
                  </svg>
                </div>
              </div>
              <div className="renewn_remainingdys mt-10 mb-10"
                   ref={daysLeftRef}>{profile?.subscriptionDetails?.daysLeft} {t("daysLeft")}</div>
            </div>
          }/>
        <div className="profileAvatarnameSec">
          {profile ? (
            <>
              <h4>{profile?.fullName}</h4>
              <p>{profile?.username}</p>
            </>
          ) : (
            <>
              <Skeleton width="70%" />
              <Skeleton count={0.5} />
            </>
          )}
          {moduleStatus?.kyc_status === 1 && (
            <div className="kycDetailProfile">
              <div className="kycDetailProfile_cnt">
                <h6>{t("kyc")}</h6>
                <h6
                  style={{
                    color: profile?.kycStatus === 1 ? "#008000" : "#FF0000",
                  }}
                >
                  {userKyc === 1 ? t("verified") : t("notVerified")}
                </h6>
              </div>
              <NavLink to={"/kyc-details"} className="kyc_more_info_btn">
                {t("moreInfo")}
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfileAvatar;
