import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import PromotionNavigation from "../../components/Tools/promotion-tools/PromotionNavigation";
import { ApiHook } from "../../hooks/apiHook";
import {useDispatch, useSelector} from "react-redux";
import {setHeader} from "../../store/reducers/pageReducer";

const PromotionTools = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("promotion-tools")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

    const [tab, setTab] = useState("email")
    const promotionDetails = ApiHook.CallGetPrmotionDetails(tab);
    return (
        <>
            <div className="page_head_top">{pageHeader}</div>
            <PromotionNavigation tab={tab} setTab={setTab} data={promotionDetails?.data?.data || []} isLoading={promotionDetails.isFetching} />
        </>
    )
};

export default PromotionTools;