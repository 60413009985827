import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {formatDate} from "../../utils/formateDate";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import {ApiHook} from "../../hooks/apiHook";
import {useReactToPrint} from "react-to-print";
import {setHeader} from "../../store/reducers/pageReducer";

const UpgradeComplete = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("upgrade")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

    const tableRef = useRef();
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const userSelectedCurrency = useSelector(
        (state) => state?.user?.selectedCurrency
    );
    const selectedLanguage = useSelector(
        (state) => state?.user?.selectedLanguage
    );
    const [successMessageShow, setSuccessMessageShow] = useState(true);
    const params = useParams();
    const invoiceNo = params?.invoiceNo;
    const order = ApiHook.CallGetOrder("upgrade", invoiceNo);
    const user = order?.data?.userData;
    const packageData = order?.data?.items?.find(item => item.type === "registration");
    const letterContent = order?.data?.letter;
    const selectedLanguageId = selectedLanguage?.id;
    const selectedLetterContent = letterContent?.find(item => item.languageId === selectedLanguageId);
    const letterContentHtml = selectedLetterContent?.content || "";
    const date = order?.data?.date;
    const closeAlert = () => {
        setSuccessMessageShow(false);
    };
    const companyProfile = useSelector(
        (state) => state?.dashboard?.appLayout?.companyProfile
    );
    // Use useEffect to automatically call closeAlert after 3 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            closeAlert();
        }, 3000);

        // Clear the timer when the component unmounts or when successMessageShow changes to false
        return () => {
            clearTimeout(timer);
        };
    }, [successMessageShow]);

    const handlePrint = useReactToPrint({
        contentRef: tableRef
    });

    return (
        <>
            <div className="d-lg-block d-flex mb-3 justify-content-between text-end">
                <div className="page_head_top">{pageHeader}</div>
                <button type="button" className="btn btn-primary print-button"
                        onClick={handlePrint}><span className="btn-label"><i className="fa fa-print"></i></span>{" " + t("print")}</button>
            </div>
            {/*{successMessageShow && (
        <div
          className="alertNotification"
          style={{
            width: "100%",
            border: "1px solid #A377FF",
            background: "#D4CBFF",
            //display: "inline-block",
            color: "#8349ff",
            padding: "8px 15px 8px 15px",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <p style={{ margin: "0" }}>{t("regCompleted")}</p>
          <div className="closeBtnIcon" onClick={closeAlert}>
            <i className="fa-solid fa-times"></i>
          </div>
        </div>
      )}*/}
            <div className="ewallet_table_section" ref={tableRef}>
                <div className="table-responsive min-height-table rounded">
                    <div className="cmpnyAddressname">
                        <div>
                            <img src={companyProfile?.logo ?? null} alt=""/>
                        </div>
                        <div>
                            <p className="mb-1">{companyProfile?.name}</p>
                            <p>{companyProfile?.address}</p>
                        </div>
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <img src={companyProfile?.logo ?? null} alt="" />
              </div>
              <div >
                <p className="font-weight-bold mb-1">
                  {`${companyProfile?.name}`}
                </p>
                <p className="font-weight-bold mb-1">
                  {`${companyProfile?.address}`}
                </p>
                <p className="text-muted">
                  {`${t("date")}: ${new Date().toLocaleDateString('en-GB')}`}
                </p>
              </div>
            </div> */}
                    <table className="striped">
                        <tbody>
                        <tr>
                            <td>{t("username")}</td>
                            <td>{user?.username}</td>
                        </tr>
                        <tr>
                            <td>{t("name")}</td>
                            <td>{user?.fullName}</td>
                        </tr>
                        <tr>
                            <td>{t("orderDate")}</td>
                            <td>{formatDate(date)}</td>
                        </tr>
                        {packageData && (
                            <>
                                <tr>
                                    <td>{t("package")}</td>
                                    <td>{packageData?.name}</td>
                                </tr>
                                <tr>
                                    <td>{t("packageAmount")}</td>
                                    <td>
                                        {userSelectedCurrency?.symbolLeft} {CurrencyConverter(packageData?.price, conversionFactor)}
                                    </td>
                                </tr>
                            </>
                        )}
                        {order?.data?.surchargeAmount > 0 && (
                            <tr>
                                <td>{t("processingFee")}</td>
                                <td>
                                    {userSelectedCurrency?.symbolLeft} {CurrencyConverter(order.data?.surchargeAmount, conversionFactor)}
                                </td>
                            </tr>
                        )}
                        {order?.data?.totalAmount > 0 && (
                            <tr>
                                <td>{t("totalAmount")}</td>
                                <td>
                                    {userSelectedCurrency?.symbolLeft} {CurrencyConverter(order.data?.totalAmount, conversionFactor)}
                                </td>
                            </tr>
                        )}
                        {order?.data?.paymentMethod && ((order.data?.paymentMethod.url &&
                            <tr>
                                <td>{t("payNow")} - {order.data?.paymentMethod.title}</td>
                                <td>
                                    <a href={order.data?.paymentMethod.url} target="_blank" rel="noreferrer"
                                       className="btn btn-success">{t("payNow")}</a>
                                </td>
                            </tr>
                        ) || (
                            <tr>
                                <td>{t("paymentMethod")}</td>
                                <td>{order.data.paymentMethod.title} {order.data.paymentMethod.status && ("(" + t(order.data.paymentMethod.status) + ")")}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="regardBg rounded">
                    <div className="mb-2">
                        <div dangerouslySetInnerHTML={{__html: letterContentHtml}}/>
                    </div>
                    <p>{t("wishingRegards")}</p>
                    <p>{companyProfile?.name}</p>
                </div>
            </div>
        </>
    );
};

export default UpgradeComplete;
