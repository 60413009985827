import React, {useEffect} from "react";
import {ApiHook} from "../../hooks/apiHook";
import {useNavigate, useParams} from "react-router";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setHeader} from "../../store/reducers/pageReducer";

const News = (newsId) => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("news")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

    const navigate = useNavigate();
    const params = useParams();
    const [articleId, setArticleId] = useState(params?.newsId);

    const article = ApiHook.CallGetNewsById(articleId);
    const news = ApiHook.CallGetNews();
    const handleNewsExpand = (newsId) => {
        setArticleId(newsId);
        navigate(`/news/${newsId}`);
    };

    const handleBack = () => {
        navigate(`/news`);
    };
    return (
        <>
            <div className="page_head_top">{pageHeader}</div>
            <div className="newsMainBg">
                {news?.data?.length > 0 ? (
                    <>
                        {params?.newsId ? (
                            <>
                                <button
                                    type="button"
                                    className="btn btn-dark text-white float-end rounded-3"
                                    onClick={handleBack}

                                >
                                    {t("back")}
                                </button>
                                <div className="newsSubExpandBg">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={article?.data?.image} alt=""/>
                                        </div>
                                        <div className="col-md-8">
                                            <p><i>{article?.data.date}</i></p>
                                            <h4 style={{wordWrap: "break-word"}}>{article?.data?.title}</h4>
                                            <p style={{wordWrap: "break-word"}}>{article?.data?.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="row">
                                {news?.data?.map((item) => (
                                    <div className="col-md-4" key={item.id}>
                                        <div
                                            className="newsSubBg"
                                            onClick={() => handleNewsExpand(item?.id)}
                                        >
                                            <div className="row">
                                                <div className="col-md-4">
                                                    {item?.image &&
                                                        <img src={item?.image} alt=""/>
                                                    }
                                                </div>
                                                <div className="col-md-8">
                                                    <p><i>{item.date}</i></p>
                                                    <h4 style={{
                                                        maxHeight: "57px",
                                                        minHeight: "57px",
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden"
                                                    }}>{item?.title}</h4>
                                                    <p style={{textOverflow: "ellipsis", overflow: "hidden"}}>
                                                        {item?.description.slice(0, 100)}
                                                        {item?.description.length > 100 && "..."}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <img src="/images/news-no-data.png" alt=""/>
                        <div>{t("sorry_no_data_found")}</div>
                    </>
                )}
            </div>
        </>
    );
};

export default News;
