import React from 'react';
import { ApiHook } from '../../../hooks/apiHook';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

const CloseParty = (props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient();
  const closePartyMutation = ApiHook.CallCloseParty()

  const closeParty = () => {
    if (props.selectedParty !== null) {
      const data = { partyId: props.selectedParty.value }
      closePartyMutation.mutate(data, {
        onSuccess: (res) => {
          if (res.status) {
            toast.success(t(res.data));
            queryClient.invalidateQueries({ queryKey: ['all-parties'] });
            queryClient.invalidateQueries({ queryKey: ["party-details"] });
          } else {
            toast.error(t(res.data.description));
          }
        }
      })
    } else {
      toast.error(t("selectAParty"));
    }
  }
  return (
    <div className="table-responsive min-height-table-2 mt-3">
      <div className="p-3">
        <div className="regsiter_step_1_view_left_sec_head mb-0">Close The Party</div>
        <span className="inter-line-hori"></span>
        <p className="mt-2">
          When all steps have been completed, you must close the party. Once it is closed,
          it will not be possible to make changes to a party.
        </p>
        <button
          className="btn mt-2 rounded-3 setup-Party text-white"
          onClick={closeParty}
        >
          {t('closeParty')}
        </button>
      </div>
    </div>
  );
};

export default CloseParty;
