import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import RenewComponents from "../../components/Renew/RenewComponents";
import { ApiHook } from "../../hooks/apiHook";
import RenewPaymentModal from "../../components/Renew/RenewPaymentModal";
import {useDispatch, useSelector} from "react-redux";
import {setHeader} from "../../store/reducers/pageReducer";

const Renew = () => {
  const { t } = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("renew")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

  const [show, setShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const conversionFactor = useSelector(
      (state) => state?.user?.conversionFactor
  );
  const userSelectedCurrency = useSelector(
      (state) => state.user?.selectedCurrency
  );
  const handleRenewClick = (product) => {
    setShow(true);
    setSelectedProduct(product);
  };
  const products = ApiHook.CallGetInventory("renewal");
  const subscriptionDetails = ApiHook.CallGetSubscriptionDetails();
  const currentProduct = subscriptionDetails?.currentProduct;
  const approvalPendingStatus = subscriptionDetails?.approvalPendingStatus;
  const invoiceNo = subscriptionDetails?.invoiceNo;

  return (
      <>
        <div className="page_head_top">{pageHeader}</div>
        <RenewComponents
            products={products}
            subscriptionDetails={subscriptionDetails}
            handleRenewClick={handleRenewClick}
            currency={userSelectedCurrency}
            conversionFactor={conversionFactor}
        />
        <RenewPaymentModal
            show={show}
            setShow={setShow}
            subscriptionDetails={subscriptionDetails}
            currentProduct={currentProduct}
            product={selectedProduct}

            currency={userSelectedCurrency}
            conversionFactor={conversionFactor}
            approvalPendingStatus={approvalPendingStatus}
            invoiceNo={invoiceNo}
        />
      </>
  );
};

export default Renew;
