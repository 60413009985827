import React, {useEffect, useState} from "react";
import UserProfileAvatar from "../../components/Profile/UserProfileAvatar";
import UserProfileExtra from "../../components/Profile/UserProfileExtra";
import ChangePasswordModal from "../../components/Common/modals/ChangePasswordModal";
import ChangeTransPassModal from "../../components/Common/modals/ChangeTransPassModal";
import UserProfileTabs from "../../components/Profile/UserProfileTabs";
import {ApiHook} from "../../hooks/apiHook";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import RankViewModal from "../../components/Common/modals/RankViewModal";
import {setHeader} from "../../store/reducers/pageReducer";

const ProfileLayout = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("profileView")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showTransPasswordModal, setShowTransPasswordModal] = useState(false);
    const [showRankView, setShowRankView] = useState(false);

    const handleClosePasswordModal = () => {
        setShowPasswordModal(!showPasswordModal);
    };
    const handleCloseTransPasswordModal = () => {
        setShowTransPasswordModal(!showTransPasswordModal);
    };
    const handleCloseRankView = () => {
        setShowRankView(!showRankView);
    };
    const Profile = ApiHook.CallProfile();
    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );

    return (
        <>
            <div className="page_head_top">{pageHeader}</div>
            <div className="profileBgBox">
                <div className="row align-items-center">
                    <UserProfileAvatar
                        profile={Profile?.data?.profile}
                        moduleStatus={moduleStatus}
                        userKyc={Profile?.data?.profile?.kycStatus}
                    />
                    <UserProfileExtra
                        profile={Profile?.data?.profile}
                        handleClosePasswordModal={handleClosePasswordModal}
                        handleCloseTransPasswordModal={handleCloseTransPasswordModal}
                        handleCloseRankView={handleCloseRankView}
                        moduleStatus={moduleStatus}
                    />
                </div>
            </div>
            <UserProfileTabs profile={Profile?.data}/>
            <ChangePasswordModal
                showModal={showPasswordModal}
                onHide={handleClosePasswordModal}
                passwordPolicy={Profile?.data?.passwordPolicy}
            />
            <ChangeTransPassModal
                showModal={showTransPasswordModal}
                onHide={handleCloseTransPasswordModal}
                passwordPolicy={Profile?.data?.passwordPolicy}
                showCurrent={Profile?.data?.transactionPassword}
            />
            <RankViewModal
                show={showRankView}
                handleClose={handleCloseRankView}
                data={Profile?.data?.profile?.rankDetails?.rankData}
                currentRank={parseInt(Profile?.data?.profile?.rankDetails?.currentRank?.id ?? 0)}
            />

        </>
    );
};

export default ProfileLayout;
