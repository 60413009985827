import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import { privateRoutes, publicRoutes, webRoutes } from "./Routes/router";
import MainLayout from "./Layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated, setLoginResponse } from "./store/reducers/userReducer";
import React, { useEffect } from "react";
import { setLoginResponseCheck } from "./utils/checkStorage";
import {setHeader} from "./store/reducers/pageReducer";

function App() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user?.isAuthenticated);
  const token = !!localStorage.getItem("access-token");
  const isAuthenticated = isLogin || token;
  const pageHeader = useSelector((state) => state?.page?.header);
  const companyProfile = useSelector(
      (state) => state?.dashboard?.appLayout?.companyProfile
  );

  useEffect(() => {
    if (token) {
      const data = setLoginResponseCheck();
      dispatch(setLoginResponse(data));
      dispatch(setIsAuthenticated(true));
    }
  }, [dispatch, token]);

  /*const usePrevious = (value) => {
    const ref = React.useRef();
    useEffect(() => { ref.current = value });

    return ref.current;
  };

  const useLocationChange = (action) => {
    const location = useLocation();
    const prevLocation = usePrevious(location);
    useEffect(() => {
      action(location, prevLocation)
    }, [action, location]);
  };

  useLocationChange((location, prevLocation) => {
    if (prevLocation && prevLocation.pathname !== location.pathname) dispatch(setHeader(null));
  });*/

  useEffect(() => {
    if (companyProfile?.name) {
      document.title = companyProfile?.name + ((!!pageHeader && ' - ' + pageHeader) || '');
    }
  }, [companyProfile?.name, pageHeader]);

  const renderPrivateRoutes = () => {
    return privateRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };

  const renderWebRoutes = () => {
    return webRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };

  const renderPublicRoutes = () => {
    return publicRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };
  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route
            path="/login"
            element={<Navigate to="/dashboard" replace />}
          />
          <Route path="/" element={<MainLayout />}>
            {renderPrivateRoutes()}
          </Route>
          {renderWebRoutes()}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </>
      ) : (
        <>
          {renderPublicRoutes()}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      )}
    </Routes>
  );
}

export default App;
