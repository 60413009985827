import DownloadMaterialsComponent from "../../components/Tools/DownloadMaterialsComponent";
import {ApiHook} from "../../hooks/apiHook";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setHeader} from "../../store/reducers/pageReducer";

const DownloadMaterials = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("download-materials")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

    const DownloadMaterials = ApiHook.CallGetDownloadMaterials();
    return (
        <>
            <div className="page_head_top">{pageHeader}</div>
            <DownloadMaterialsComponent materials={DownloadMaterials?.data}/>
        </>
    );
};
export default DownloadMaterials;
