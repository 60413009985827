import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import TicketHeader from "../../components/Support/TicketHeader";
import TicketsTable from "../../components/Support/TicketsTable";
import {ApiHook} from "../../hooks/apiHook";
import {useDispatch, useSelector} from "react-redux";
import {setHeader} from "../../store/reducers/pageReducer";

const MainSupport = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("support")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedOptions, setSelectedOptions] = useState({
        ticketId: "",
        selectedCategoryOption: "",
        selectedPriorityOption: "",
        selectedStatusOption: "",
    });
    const [selectedMenu, setSelectedMenu] = useState(false);

    //------------------------------------------- API -----------------------------------------

    const tickets = ApiHook.CallTickets(
        currentPage,
        itemsPerPage,
        selectedOptions.selectedCategoryOption,
        selectedOptions.selectedPriorityOption,
        selectedOptions.ticketId,
        selectedOptions.selectedStatusOption,
    );

    const handleSideMenuToggle = () => {
        setSelectedMenu(!selectedMenu);
    };

    return (
        <>
            <div className={`page_head_top ${selectedMenu ? 'show_mailbox_sidebar_ico' : ''}`}>
                {pageHeader}
                <div className="right_btn_mob_toggle" onClick={handleSideMenuToggle}>
                    <i className="fa fa-bars"></i>
                </div>
            </div>
            <TicketHeader selectedMenu={selectedMenu} />
            <TicketsTable
                data={tickets?.data}
                type={"ticket"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
            />
        </>
    );
};

export default MainSupport;
