import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import UpgradeComponents from "../../components/upgrade/UpgradeComponents";
import {ApiHook} from "../../hooks/apiHook";
import UpgradePaymentModal from "../../components/upgrade/UpgradePaymentModal";
import {useDispatch, useSelector} from "react-redux";
import {setHeader} from "../../store/reducers/pageReducer";
//import { toast } from "react-toastify";

const Upgrade = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("upgrade")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

    const [show, setShow] = useState(false);
    const [selectedUpgradePlan, setSelectedUpgradePlan] = useState();

    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const handleUpgradeClick = (plan) => {
        setShow(true);
        setSelectedUpgradePlan(plan);
    };
    const productsList = ApiHook.CallGetUpgradeProducts();
    const currentProduct = productsList && productsList.upgradeData?.find((item) => item.upgradable === 1);
    const approvalPendingStatus = productsList?.approvalPendingStatus;
    const invoiceNo = productsList?.invoiceNo;

    return (
        <>
            <div className="page_head_top">{pageHeader}</div>
            <UpgradeComponents
                data={productsList && productsList.upgradeData}
                handleUpgradeClick={handleUpgradeClick}
                currency={userSelectedCurrency}
                conversionFactor={conversionFactor}
            />
            <UpgradePaymentModal
                show={show}
                setShow={setShow}
                currentProduct={currentProduct}
                data={selectedUpgradePlan}
                currency={userSelectedCurrency}
                conversionFactor={conversionFactor}
                approvalPendingStatus={approvalPendingStatus}
                invoiceNo={invoiceNo}
            />
        </>
    );
};

export default Upgrade;
