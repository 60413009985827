import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router";
import {useReactToPrint} from "react-to-print";
import {ApiHook} from "../../hooks/apiHook";
import {useDispatch, useSelector} from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import {formatDate} from "../../utils/formateDate";
import {setHeader} from "../../store/reducers/pageReducer";

const OrderInvoice = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("order_invoice")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

    const navigate = useNavigate()
    const params = useParams();
    const tableRef = useRef(null);
    const invoiceNo = params.invoiceNo;

    const reactToPrintFn = useReactToPrint({contentRef: tableRef});

    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const userSelectedCurrency = useSelector(
        (state) => state?.user?.selectedCurrency
    );

    const companyProfile = useSelector(
        (state) => state?.dashboard?.appLayout?.companyProfile
    );

    const handleBack = () => {
        navigate('/order-report')
    }

    //---------------------------------- API ------------------------------------
    const invoice = ApiHook.CallOrderInvoice(invoiceNo);

    return (
        <>
            <div className="d-lg-block d-flex mb-3 justify-content-between text-end">
                <div className="page_head_top">{pageHeader}</div>
                <button type="button" className="btn btn-primary print-button"
                        onClick={reactToPrintFn}><span className="btn-label"><i
                    className="fa fa-print"></i></span>{" " + t("print")}</button>
            </div>
            <div className="purchase_invoice" ref={tableRef}>
                <button className="print-button" style={{backgroundColor: 'white'}} onClick={handleBack}>
                    <i className="fa fa-angle-left" style={{fontSize: "1.3em"}}></i>
                </button>
                <div className="row p-2">
                    <div className="col-md-8">
                        <img src={companyProfile?.logo ?? null} alt="" style={{height: "80px"}}/>
                    </div>
                    <div className="col-md-4 text-end">
                        <p className="font-weight-bold mb-1">
                            {t(invoice?.data?.orderType)}
                        </p>
                        <p className="font-weight-bold mb-1">
                            {`${t("invoice_no")}: ${invoice?.data?.invoiceNo}`}
                        </p>
                        <p className="text-muted">
                            Date: {formatDate(invoice?.data?.date)}
                        </p>
                    </div>
                </div>

                <hr className="my-1"/>

                <div className="row pb-1 p-1">
                    <div className="col-md-6">
                        <p className="font-weight-bold mb-4">
                            {t("client_information")}
                        </p>
                        <p className="mb-1">{invoice?.data?.clientInfo?.name}</p>
                        <p className="mb-1">{invoice?.data?.clientInfo?.address}</p>
                        {invoice?.data?.clientInfo?.address2 &&
                            <p className="mb-1">{invoice?.data?.clientInfo?.address2}</p>}
                        <p className="mb-1">{invoice?.data?.clientInfo?.city && invoice?.data?.clientInfo?.city + ","} {invoice?.data?.clientInfo?.state} {invoice?.data?.clientInfo?.postalCode}</p>
                        <p className="mb-1">{invoice?.data?.clientInfo?.country}</p>
                    </div>

                    <div className="col-md-6 text-md-end">
                        <p className="font-weight-bold mb-4">{t("payment_details")}</p>
                        {invoice?.data?.paymentMethod && ((invoice.data?.paymentMethod.url &&
                            <>
                                <a href={invoice.data?.paymentMethod.url} target="_blank" rel="noreferrer"
                                   className="btn btn-success">{t("payNow")}</a>
                                <p className="mb-1">
                                    <span className="text-muted">{t("paymentMethod")}: </span>
                                    {invoice.data.paymentMethod.title} {invoice.data.paymentMethod.status && ("(" + t(invoice.data.paymentMethod.status) + ")")}
                                </p>
                            </>
                        ) || (
                            <p className="mb-1">
                                <span className="text-muted">{t("paymentMethod")}: </span>
                                {invoice.data.paymentMethod.title} {invoice.data.paymentMethod.status && ("(" + t(invoice.data.paymentMethod.status) + ")")}
                            </p>
                        ))}
                    </div>
                </div>

                <div className="row p-1">
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                            <tr>
                                <th className="text-uppercase small font-weight-bold">
                                    #
                                </th>
                                <th className="text-uppercase small font-weight-bold">
                                    {t("package")}
                                </th>
                                <th className="text-uppercase small font-weight-bold">
                                    {t("quantity")}
                                </th>
                                <th className="text-uppercase small font-weight-bold text-end">
                                    {t("total")}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {invoice?.data?.items?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.package}</td>
                                    <td>{item.quantity}</td>
                                    <td className="text-end">
                                        {`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(item.amount, conversionFactor, 0, false)}`}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            {!!invoice?.data?.surchargeAmount &&
                                <tfoot>
                                <tr>
                                    <td colSpan="4"
                                        className={"text-end"}>{t("processingFee")}: {`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(invoice?.data?.surchargeAmount, conversionFactor, 0, false)}`}</td>
                                </tr>
                                </tfoot>
                            }
                        </table>
                    </div>
                </div>

                <div className="d-flex flex-row-reverse text-white invoice-total p-4">
                    <div className="py-1 px-1 text-end">
                        <div className="mb-2">{t("grand_total")}</div>
                        <div className="h2 font-weight-light">
                            {`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(invoice?.data?.grandTotal, conversionFactor, 0, false)}`}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderInvoice;
