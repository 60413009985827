import {renewService} from "../../services/renew/renew";

export const RenewActions = {
  getSubscriptionDetails: async () => {
    try {
      return await renewService.getSubscriptionDetails();
    } catch (error) {
      console.log(error.message);
    }
  },
  renewSubscription: async (data) => {
    try {
      const response = await renewService.renewSubscription(data);
      return response.data
    } catch (error) {
      return error
    }
  },
  AutoSubscription: async (data) => {
    try {
      return await renewService.autoSubscription(data);
    } catch (error) {
      return error.message
    }
  },
  CancelSubscription: async (data) => {
    try {
      return await renewService.cancelSubscription(data);
    } catch (error) {
      return error.message
    }
  },
};
