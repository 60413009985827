import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import OrderTable from "../../components/shopping/OrderTable";
import { ApiHook } from "../../hooks/apiHook";
import {useDispatch, useSelector} from "react-redux";
import {setHeader} from "../../store/reducers/pageReducer";

const OrderReport = () => {
  const { t } = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeader(t("order_report")));
    }, [dispatch, t]);
    const pageHeader = useSelector((state) => state?.page?.header);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    direction: ""
  })
  //-------------------------------- API ---------------------------------------
  const report = ApiHook.CallOrderReport(currentPage,itemsPerPage, sortOptions.sortBy, sortOptions.direction)
  
  return (
    <>
      <div className="page_head_top">{pageHeader}</div>
      <OrderTable
        data={report.data}
        type={"order-report"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        setSortOptions={setSortOptions}
      />
    </>
  );
};

export default OrderReport;
